import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 831.378 593.721"
    // width={1662.757}
    // height={1187.443}
    {...props}
  >
    <defs>
      <style>
        {
          '@font-face{font-family:"Virgil";src:url(https://excalidraw.com/Virgil.woff2)}@font-face{font-family:"Cascadia";src:url(https://excalidraw.com/Cascadia.woff2)}'
        }
      </style>
    </defs>
    <path
      d="M10.63 9.67c96.89-.66 196.04 1.67 255.92-1.12M9.27 10.38c91.02-.03 180.33-.03 257.78.34m-1.73-.5c1.93 29.48 2.88 59.98-.46 74.51m1.95-74.27c.07 21.47-1.32 42.78-.52 73.99m-.32.79c-59.21-1.47-122.47.44-254.74 1.09m255.05-.71c-75.57-.3-151.55.76-255.88.11m1.09-1.6C8.58 66.66 9.26 42.91 9.07 8.65m1.02 75.72c1.49-24.64.93-49.7-.87-75.18M9.312 105.396c87.14 1.43 172.25.09 257.58-2.03m-256.07 1.44c83.35.42 167.87.34 257.57-.18m-1.43-.08c-.26 23.05-1.12 49.19.74 75.11m1.1-75.99c-.5 17.57-1.15 33.85-.99 74.95m.81.33c-72.11-1.25-143.55.37-259.06-.34m258.75.83c-64.96-.64-130.31-1.37-257.75-.22m.44-.04c-.87-27.19-1.96-57.15.16-74.6m-1.5 75.55c1.17-29.33 1.77-57.98 1.28-75.5"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
    />
    <text
      y={18}
      fontFamily="Virgil, Segoe UI Emoji"
      fontSize={20}
      fill="#343a40"
      style={{
        whiteSpace: "pre",
      }}
      transform="translate(320.468 128.411)"
    >
      {"Lorem ipsum dolor sit amet. "}
    </text>
    <text
      y={18}
      fontFamily="Virgil, Segoe UI Emoji"
      fontSize={20}
      fill="#343a40"
      style={{
        whiteSpace: "pre",
      }}
      transform="translate(325.378 10.659)"
    >
      {"Aliquam sed magna quam. Proin interdum id purus "}
    </text>
    <text
      y={43}
      fontFamily="Virgil, Segoe UI Emoji"
      fontSize={20}
      fill="#343a40"
      style={{
        whiteSpace: "pre",
      }}
      transform="translate(325.378 10.659)"
    >
      {"Bibendum feugiat. Integer vitae est magna. Nunc "}
    </text>
    <text
      y={68}
      fontFamily="Virgil, Segoe UI Emoji"
      fontSize={20}
      fill="#343a40"
      style={{
        whiteSpace: "pre",
      }}
      transform="translate(325.378 10.659)"
    >
      {"fermentum vel elit eu condimentum. "}
    </text>
    <path
      d="M11.798 209.772c98.28-2.26 194.36-2.4 257-2.12m-257 1.19c75.04-1.06 149.72-.76 257.21-1.36m1.83.33c-.82 24.7-2.07 50.16-1.04 74.29m.2-74.59c.06 27.91.07 53.67.03 75.66m-1.64.93c-59.06-.11-116.78.57-256.47-2.08m256.83.81c-63.94 1.37-130.18.85-256.61.26m.41-.81c-.42-23.61-.01-46.91.76-73.73m-2.24 75.73c1.13-17.92.49-35.11 1.54-75.08"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
    />
    <text
      y={18}
      fontFamily="Virgil, Segoe UI Emoji"
      fontSize={20}
      fill="#343a40"
      style={{
        whiteSpace: "pre",
      }}
      transform="translate(321.684 232.715)"
    >
      {"Aliquam sed magna quam. Proin "}
    </text>
    <text
      y={43}
      fontFamily="Virgil, Segoe UI Emoji"
      fontSize={20}
      fill="#343a40"
      style={{
        whiteSpace: "pre",
      }}
      transform="translate(321.684 232.715)"
    >
      {"interdum id purus "}
    </text>
    <path
      d="M14.67 308.88c91.91 3.15 185.84 2.66 253.42.19m-255.1 1.56c66.43-.53 131.78-.23 256.2-.08m1.61-.79c-3.53 29.02-2.71 60.89-.42 76.08m-.19-75.4c-.67 22.7-1.02 47.14-.96 74.62m1.42-1.04c-79.48.31-156.03 2.25-256.74.63m255.78 1.48c-65.2 2.13-128.2 1.93-256.04-.1m-.49-2.23c.44-26.05-1.27-53.66-1.66-74.4m2.04 75.35c-1.23-24.97-.86-50.2-.08-73.56M12.222 404.994c56.31-1.1 110.57-3.79 257.87-1.13m-256.12 1.09c59.25-.25 118.75-.33 257.6.34m-.79-2.25c-.91 16.8 1.46 34.41.78 77.01m-.1-76.13c-.72 29.27.38 59.66-.68 75.95m-1.04-.79c-65.99 2.5-130.88 3.12-257.05-.02m259.16.17c-79.8 1.98-159.9 1.81-257.78 1.19m-2.23-.16c1.72-23.56 2.09-45.73.9-74.99m.05 73.85c.73-22.89.79-45.42 1.74-74.09"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
    />
    <text
      y={18}
      fontFamily="Virgil, Segoe UI Emoji"
      fontSize={20}
      fill="#343a40"
      style={{
        whiteSpace: "pre",
      }}
      transform="translate(329.422 329.153)"
    >
      {"Lorem ipsum dolor sit amet. "}
    </text>
    <path
      d="M16.128 508.161c53.13-1.18 109.6-1.16 256.78.02m-258.29 1c90.32-1.6 181.71-2.64 257.38-.22m-.23.9c1.06 19.72.32 42.11.48 75.36m-.76-76.12c.29 23.12.52 47.43.72 75.37m.43.52c-52.72-2.99-107.63-1.96-256.29-.77m256.82.02c-97.11 1.85-194.03 1.26-258.18-.5m-1.5.55c-.47-17.49 1.85-32.59.22-75m.7 74.05c1.27-15.46 1.4-33.62 1.03-75.78"
      stroke="#000"
      fill="none"
      strokeLinecap="round"
    />
    <text
      y={18}
      fontFamily="Virgil, Segoe UI Emoji"
      fontSize={20}
      fill="#343a40"
      style={{
        whiteSpace: "pre",
      }}
      transform="translate(325.02 532.717)"
    >
      {"Lorem ipsum dolor sit amet. "}
    </text>
    <g strokeLinecap="round">
      <path
        d="M143.614 125.158c4.06.44 8.55 3.8 11.54 7.06 3 3.25 6.03 8.27 6.42 12.48.39 4.21-1.45 9.34-4.08 12.78-2.64 3.44-7.36 6.66-11.72 7.84-4.35 1.18-10.5 1.36-14.42-.77-3.92-2.12-7.41-7.91-9.08-11.97-1.66-4.06-2.17-8.14-.91-12.39 1.25-4.26 4.47-10.71 8.46-13.13 3.98-2.43 12.68-1.18 15.44-1.4 2.77-.21 1.47-.22 1.16.11m-15.09 1.33c3.59-1.76 8.58-1.37 13-.54 4.43.82 10.76 2.06 13.54 5.48 2.79 3.41 3.51 10.58 3.18 14.99-.34 4.41-2.15 8.49-5.19 11.44-3.04 2.95-8.71 5.49-13.04 6.27-4.32.78-9.19.46-12.9-1.59-3.72-2.05-7.83-6.46-9.39-10.71-1.56-4.25-1.58-10.34.05-14.78 1.63-4.44 7.88-10.16 9.73-11.85 1.85-1.68 1.17 1.21 1.37 1.71"
        strokeWidth={0}
        fill="#fff"
      />
      <path
        d="M146.164 125.618c3.93.92 9.41 4.53 11.75 8.21 2.34 3.69 2.91 9.54 2.3 13.9-.62 4.36-2.83 9.2-6.01 12.25-3.18 3.04-8.64 5.76-13.07 6.02-4.43.26-10.07-1.73-13.5-4.47-3.43-2.73-6.25-7.74-7.06-11.96-.8-4.22.13-9.4 2.24-13.36 2.11-3.96 6.45-8.85 10.43-10.38 3.98-1.53 10.96.96 13.46 1.2 2.5.25 1.36.02 1.54.27m-2.49-1.97c4.22.74 9.03 3.87 11.8 7.62 2.77 3.76 5.1 10.19 4.82 14.9-.29 4.71-3.08 10.39-6.53 13.36-3.46 2.96-9.87 4.28-14.22 4.42-4.35.15-8.27-1.23-11.91-3.57-3.64-2.34-8.62-6.09-9.92-10.46-1.31-4.38.09-11.48 2.1-15.81 2-4.32 5.89-8.28 9.93-10.14 4.04-1.87 12.14-1.29 14.3-1.06 2.16.24-1.27 2.16-1.34 2.48"
        stroke="#000"
        fill="none"
      />
    </g>
    <g strokeLinecap="round">
      <path
        d="M142.025 131.83c1.63.08 3.9 1.94 4.95 3.3 1.04 1.36 1.47 3.2 1.33 4.87-.15 1.66-1.17 3.86-2.19 5.12-1.02 1.25-2.65 2.03-3.95 2.41-1.3.39-2.39.58-3.85-.11s-4.35-2.48-4.92-4.02c-.57-1.55 1.07-3.74 1.5-5.26.44-1.53-.27-3.05 1.11-3.88 1.38-.83 5.95-.78 7.18-1.12 1.22-.34.22-.83.18-.93m-3.7-.29c1.88-.27 5.04 1.57 6.26 2.44 1.22.87.66 1.42 1.08 2.77.43 1.34 2.04 3.84 1.46 5.3-.57 1.45-3.21 2.55-4.9 3.42-1.69.88-3.88 2.08-5.23 1.84-1.35-.23-2.2-1.83-2.87-3.26-.67-1.42-1.16-3.65-1.17-5.31-.01-1.65.43-3.62 1.12-4.61.68-.99 2.24-1 2.97-1.35.72-.36 1.15-.83 1.36-.78"
        strokeWidth={0}
      />
      <path
        d="M144.105 133.13c1.29.54 2.47 1.47 3.04 2.88.57 1.41.85 3.97.36 5.59-.49 1.62-2.05 3.22-3.32 4.12a6.878 6.878 0 0 1-4.28 1.24c-1.5-.07-3.58-.68-4.7-1.67-1.12-.98-2.03-2.72-2.02-4.24.02-1.52 1.21-3.35 2.13-4.88.92-1.54 1.6-3.91 3.39-4.31 1.78-.4 6.07 1.34 7.33 1.91 1.25.57.35 1.32.21 1.51m-6.91-2.69c1.32-.14 2.9 1.11 4.37 2.01 1.48.89 3.62 2.17 4.48 3.35.85 1.19 1.3 2.16.64 3.74-.66 1.57-3.07 4.9-4.63 5.7-1.56.81-3.41-.46-4.73-.88-1.32-.43-2.07-.58-3.18-1.67-1.12-1.09-3.16-3.29-3.5-4.88-.34-1.58.47-3.21 1.44-4.62.97-1.41 3.3-3.46 4.38-3.85 1.08-.39 2.09 1.19 2.1 1.49"
        stroke="#000"
        fill="none"
      />
    </g>
    <g fillRule="evenodd" strokeLinecap="round">
      <path
        d="m128.336 156.741 5.47-3.26 8.08-3.18 7.19 1.57 5.52 3.99-3.99 4.83-4.9 3.79-7.35.52-12.75-9.16"
        strokeWidth={0}
      />
      <path
        d="M127.136 157.811c.95-.77 3.26-5.41 5.52-6.87 2.25-1.47 5.24-1.91 7.98-1.93 2.75-.01 6.2.72 8.49 1.84 2.28 1.12 5.14 3.13 5.22 4.9.08 1.77-2.96 4.37-4.72 5.71-1.76 1.33-3.7 1.84-5.84 2.3-2.14.47-4.87.78-6.99.48-2.12-.29-4.04-1.11-5.73-2.24-1.68-1.14-3.5-3.5-4.4-4.57m-.61-.09c.75-.53 3.34-4.09 5.62-5.28 2.28-1.19 5.2-1.72 8.08-1.84 2.89-.12 6.49.2 9.22 1.15 2.74.94 6.94 3.07 7.19 4.54.24 1.48-3.73 2.64-5.72 4.31-1.99 1.66-4.33 5.04-6.22 5.68-1.89.65-3.21-1.01-5.12-1.82-1.91-.81-4.44-1.93-6.36-3.05-1.92-1.12-3.99-2.84-5.17-3.67"
        stroke="#000"
        fill="none"
      />
    </g>
    <g strokeLinecap="round">
      <path
        d="M146.033 31.564c3.79 1.4 8.18 4.68 9.96 8.81 1.79 4.12 2.11 11.64.73 15.94-1.38 4.29-5.54 7.33-9.02 9.83-3.47 2.5-7.64 5.49-11.85 5.16-4.2-.34-10.07-3.86-13.38-7.18-3.31-3.32-6.06-8.5-6.46-12.75-.41-4.26 1.4-9.23 4.04-12.8 2.64-3.58 6.75-7.86 11.78-8.64 5.02-.78 14.73 2.5 18.38 3.96 3.65 1.46 3.86 4.63 3.52 4.8m-15.57-8.3c4.08-.04 10.85 1.78 13.92 4.87 3.08 3.1 4.45 9.28 4.53 13.69.09 4.42-1.6 9.1-4.02 12.82-2.43 3.72-6.5 8.4-10.52 9.52-4.01 1.12-9.32-.43-13.56-2.79s-9.87-7.02-11.86-11.37c-2-4.35-1.67-10.44-.1-14.71 1.57-4.27 5.48-8.59 9.53-10.93 4.05-2.33 12.44-2.54 14.76-3.08 2.33-.54-.41-.55-.82-.17"
        strokeWidth={0}
        fill="#fff"
      />
      <path
        d="M135.103 28.894c4.08-.35 10.25 2.08 13.96 4.67 3.71 2.6 7.07 6.7 8.3 10.9 1.23 4.21.93 10.41-.92 14.32-1.85 3.92-5.91 7.23-10.18 9.16-4.26 1.94-11 3.69-15.41 2.44-4.42-1.24-8.58-6.25-11.07-9.91s-4.35-7.84-3.88-12.06c.46-4.23 2.43-10.11 6.67-13.28 4.24-3.16 14.85-5.29 18.75-5.72 3.91-.43 4.78 2.73 4.67 3.15m-11.94-1.97c4.44-.56 10.49.84 14.13 2.97 3.64 2.13 6.4 5.89 7.7 9.8 1.29 3.91 1.38 9.41.08 13.65-1.31 4.24-4.19 9.37-7.93 11.77-3.75 2.41-9.71 3.92-14.53 2.66-4.82-1.25-11.29-6.37-14.4-10.17-3.11-3.8-4.73-8.27-4.25-12.63.48-4.36 4.1-10.36 7.15-13.54 3.05-3.18 9.13-4.79 11.15-5.54 2.03-.75.92.53 1.01 1.03"
        stroke="#000"
        fill="none"
      />
    </g>
    <g strokeLinecap="round">
      <path
        d="M141.355 37.847c1.18.57 1.35 2.12 2.01 3.4.65 1.28 2.4 2.6 1.91 4.29-.48 1.68-3.48 4.8-4.84 5.85-1.36 1.04-1.85.85-3.31.43-1.46-.43-4.28-1.76-5.47-3-1.19-1.24-1.81-2.92-1.68-4.45.14-1.53 1.45-3.47 2.48-4.72 1.02-1.25 2-2.7 3.67-2.79 1.67-.1 4.98 1.76 6.34 2.23 1.36.47 1.99.52 1.81.61m-4.09-2.89c1.14.32 1.76.7 2.52 1.85.76 1.16 2.15 3.22 2.04 5.08-.11 1.87-1.8 4.63-2.68 6.12-.87 1.49-1.17 2.91-2.57 2.81-1.4-.1-4.2-2.26-5.82-3.42-1.63-1.15-3.23-2.23-3.92-3.51-.7-1.28-.85-3.01-.27-4.17.58-1.16 2.29-2.09 3.75-2.79 1.45-.7 4.24-.95 4.96-1.39.73-.44-.62-1.36-.61-1.24"
        strokeWidth={0}
      />
      <path
        d="M139.605 37.137c1.66.52 3.87 2.05 4.66 3.56.78 1.51.43 3.85.06 5.51-.37 1.65-.99 3.6-2.28 4.43-1.28.82-3.96.64-5.42.52-1.45-.12-2.06-.25-3.3-1.25-1.24-1-3.87-3.22-4.13-4.77-.27-1.55 1.55-3.25 2.53-4.52.97-1.28 1.79-2.6 3.31-3.12s4.55-.18 5.82-.01c1.28.17 1.82.94 1.84 1.03m-7.31-.61c1.61-.91 3.76-1.95 5.08-1.68 1.33.26 2.23 1.67 2.86 3.28.62 1.6.89 4.6.9 6.35.02 1.75-.02 3.48-.8 4.16-.78.68-1.96.11-3.87-.06-1.91-.18-5.98-.45-7.6-.97-1.63-.52-1.79-.74-2.16-2.16-.36-1.42-.82-4.86-.06-6.38.76-1.52 3.74-2.17 4.63-2.72.88-.54.47-.85.68-.53"
        stroke="#000"
        fill="none"
      />
    </g>
    <g fillRule="evenodd" strokeLinecap="round">
      <path
        d="m122.625 60.548 8.14-4.48 7.25-3.21 6.32 2.71 7.22 6.62-4.01 5.75-8.01.44-6.09-.91-8.97-5.62"
        strokeWidth={0}
      />
      <path
        d="M122.565 61.738c.83-1.07 3.25-4.81 5.64-6.2 2.4-1.39 5.64-2.33 8.75-2.15 3.11.17 7.58 1.84 9.92 3.18 2.35 1.35 4.13 3.14 4.14 4.9.02 1.76-2.15 4.61-4.07 5.68-1.91 1.07-5.33.58-7.41.76-2.07.18-2.92.8-5.03.31-2.11-.48-5.83-2.02-7.63-3.21-1.81-1.2-2.58-3.34-3.21-3.97m-2.65.08c1.17-.39 6.96-3.19 9.47-4.26 2.5-1.07 2.74-2.35 5.58-2.18 2.83.17 8.59 2.52 11.41 3.2 2.83.68 5.4-.59 5.54.87.13 1.47-2.5 6.06-4.73 7.94-2.22 1.87-6.22 3.11-8.62 3.32-2.41.21-3.89-1.58-5.79-2.07-1.9-.49-3.51.33-5.61-.88-2.1-1.21-6.22-5.63-7.01-6.41"
        stroke="#000"
        fill="none"
      />
    </g>
    <g strokeLinecap="round">
      <path
        d="M123.945 522.037c3.94-2.03 9.67-2.16 13.68-.85 4 1.3 7.9 4.89 10.32 8.68 2.43 3.79 4.53 9.59 4.22 14.07-.31 4.48-2.64 9.64-6.07 12.8-3.42 3.17-9.95 5.82-14.49 6.21-4.53.39-9.17-.96-12.73-3.86-3.55-2.9-7.64-8.93-8.61-13.53-.97-4.6.04-10.08 2.77-14.08 2.74-3.99 10.45-8.11 13.64-9.89 3.2-1.77 5.25-1.1 5.54-.75m-5.83-.17c3.58-1.67 7.51-1.46 11.41.62 3.9 2.07 9.61 7.5 12.01 11.81 2.4 4.31 3.2 9.88 2.39 14.05-.81 4.18-3.44 8.42-7.23 10.98-3.79 2.57-10.82 4.45-15.52 4.43-4.71-.03-9.38-1.67-12.69-4.6-3.31-2.94-6.45-8.62-7.17-13.02-.71-4.41.48-9.68 2.87-13.4 2.39-3.72 9.48-7.36 11.46-8.93 1.98-1.56.37-.89.44-.46"
        strokeWidth={0}
        fill="#fff"
      />
      <path
        d="M139.985 522.707c3.89 1.38 7.77 4.82 9.56 8.75 1.78 3.93 1.93 10.34 1.14 14.81-.78 4.47-2.48 9.43-5.85 12.01-3.36 2.57-9.58 3.59-14.33 3.44-4.76-.15-11.07-1.59-14.2-4.34-3.12-2.76-4.01-7.6-4.56-12.18-.55-4.57-.99-11.41 1.28-15.26 2.27-3.85 7.26-6.74 12.35-7.84 5.09-1.11 14.78.48 18.2 1.24 3.42.75 2.81 2.97 2.33 3.28m-10.17-5.25c4.4.65 8.76 4.18 11.45 7.34 2.7 3.16 4.69 7.33 4.72 11.62.03 4.29-1.89 10.39-4.56 14.14s-6.99 7.34-11.44 8.35c-4.46 1-11.43-.24-15.31-2.31-3.88-2.06-6.7-5.8-7.97-10.1-1.27-4.31-.96-11.35.34-15.74 1.3-4.38 3.92-8.3 7.48-10.57 3.55-2.27 11.82-2.75 13.86-3.04 2.05-.29-1.75.79-1.58 1.31"
        stroke="#000"
        fill="none"
      />
    </g>
    <g strokeLinecap="round">
      <path
        d="M128.286 529.49c1.19-.93 3.38-1.76 4.89-1.3 1.51.45 3.24 2.48 4.17 4.04.93 1.55 1.52 3.9 1.4 5.3-.11 1.4-1.08 2.1-2.08 3.1-1.01.99-2.53 2.76-3.94 2.85-1.41.08-3.03-1.42-4.51-2.34-1.49-.91-3.76-1.64-4.39-3.17-.63-1.53-.37-4.59.58-6.01.96-1.41 4.34-2.12 5.14-2.5.8-.38-.38.27-.34.24m2.97-2.2c1.35.04 2.94 2.66 4.28 4.25 1.33 1.59 3.44 3.58 3.73 5.29.29 1.71-.74 4.1-2 4.97-1.25.88-3.8.34-5.52.27-1.73-.07-3.64.19-4.82-.68-1.18-.88-1.85-2.96-2.25-4.58-.4-1.63-.81-3.74-.18-5.17.62-1.43 2.48-2.82 3.93-3.44 1.45-.62 4.12-.18 4.77-.28.64-.11-.96-.63-.89-.37"
        strokeWidth={0}
      />
      <path
        d="M132.776 528.46c1.48-.14 2.51.35 3.55 1.72 1.03 1.38 2.46 4.77 2.68 6.53.21 1.77-.33 3.12-1.39 4.06-1.05.95-3.47 1.13-4.92 1.61-1.45.48-2.67 1.98-3.79 1.25-1.12-.72-2.17-4.01-2.94-5.61-.77-1.61-2.03-2.84-1.69-4.02.35-1.19 2.22-2.42 3.74-3.09 1.51-.66 4.07-.63 5.33-.91 1.26-.28 2.25-.79 2.23-.77m-5.3.1c1.77-.72 4.42-.6 5.69-.03 1.26.58 1.44 1.97 1.91 3.49.47 1.52 1.14 4.22.93 5.62-.21 1.4-.95 1.88-2.18 2.8-1.24.92-3.69 2.49-5.23 2.7-1.55.22-2.8-.2-4.06-1.42-1.27-1.21-3.3-4.09-3.55-5.88-.24-1.79 1.04-3.32 2.07-4.85 1.03-1.52 3.28-4.07 4.12-4.3.84-.22.72 2.58.93 2.96"
        stroke="#000"
        fill="none"
      />
    </g>
    <g fillRule="evenodd" strokeLinecap="round">
      <path
        d="m115.247 554.54 8.03-8.16 6.66-.39 9.82.61 8.08 5.97-5.77 6.55-7.37.19-7.01.66-10.21-6.1"
        strokeWidth={0}
      />
      <path
        d="M118.197 553.22c.75-.73 2.14-3.35 4.39-4.72 2.25-1.37 6.28-3.26 9.11-3.5 2.84-.24 5.56.63 7.88 2.06 2.33 1.43 5.75 4.63 6.06 6.5.31 1.87-2.26 3.79-4.19 4.73-1.94.94-5.28.41-7.4.91-2.12.49-3.58 2.55-5.32 2.06-1.75-.49-3.15-3.62-5.17-5s-5.95-2.93-6.98-3.29m.66-.64c1.06-1.08 5.28-4.62 7.57-5.66 2.28-1.04 3.73-.73 6.14-.57 2.4.15 5.51.68 8.3 1.48 2.79.8 7.85 1.68 8.44 3.32.59 1.63-2.65 4.81-4.9 6.48-2.25 1.68-6.14 2.86-8.61 3.55-2.47.69-4.27 1.09-6.23.59-1.95-.5-3.68-2.1-5.5-3.59-1.83-1.5-4.67-4.41-5.44-5.39"
        stroke="#000"
        fill="none"
      />
    </g>
    <g strokeLinecap="round">
      <path
        d="M130.384 328.223c3.92-1.85 10.47-1.06 14.56.51 4.1 1.58 7.82 5.01 10.01 8.93 2.19 3.91 3.76 10.12 3.13 14.57-.63 4.46-3.65 9.27-6.9 12.16-3.26 2.88-8.31 5.2-12.65 5.17-4.34-.04-9.89-2.56-13.41-5.38-3.52-2.83-6.71-7.36-7.72-11.59-1-4.23-.46-9.9 1.69-13.8 2.15-3.9 9.12-7.81 11.21-9.6 2.09-1.79 1.21-1.4 1.35-1.14m-1.02-1.04c3.85-1.45 9.63.74 13.92 2.4 4.3 1.67 9.72 3.72 11.85 7.6 2.14 3.88 1.73 10.91.99 15.69-.75 4.79-2.29 10.26-5.46 13s-9.07 3.75-13.57 3.45c-4.51-.3-9.72-2.08-13.45-5.27-3.73-3.19-8.26-9.71-8.92-13.89-.66-4.18 2.67-7.65 4.96-11.21 2.29-3.56 6.92-8.7 8.78-10.16 1.87-1.45 2.11.99 2.4 1.44"
        strokeWidth={0}
        fill="#fff"
      />
      <path
        d="M131.844 328.393c4-1.47 9.41-1.57 13.53.08 4.12 1.64 9.1 5.91 11.19 9.78 2.08 3.88 2.25 9.2 1.32 13.46-.93 4.27-3.39 9.17-6.9 12.13-3.52 2.96-9.46 5.82-14.19 5.65-4.73-.16-10.87-3.41-14.2-6.65-3.34-3.24-5.5-8.47-5.81-12.8-.32-4.32 1.12-9.33 3.91-13.13 2.79-3.8 10.34-8.32 12.84-9.69 2.51-1.38 2.13 1.14 2.2 1.43m10.24 2.06c4.19 1.79 10.02 7.07 11.71 11.03 1.7 3.96.2 8.89-1.55 12.73-1.74 3.85-5.5 8.19-8.93 10.32-3.44 2.13-7.28 2.73-11.69 2.45-4.41-.28-11.38-1.28-14.76-4.14-3.39-2.85-5.63-8.41-5.53-13 .1-4.59 3.15-10.96 6.13-14.56 2.99-3.59 7.46-6.18 11.78-7.02 4.31-.83 11.85 1.65 14.1 2.02 2.24.37-.31.16-.62.22"
        stroke="#000"
        fill="none"
      />
    </g>
    <g strokeLinecap="round">
      <path
        d="M138.505 335.895c1.41.03 3.99.33 5.14 1.43 1.15 1.09 1.65 3.5 1.78 5.15.13 1.66-.07 3.44-1 4.78-.93 1.33-2.99 2.82-4.56 3.23-1.57.41-3.38.06-4.86-.77-1.48-.83-3.45-2.59-4.03-4.23-.59-1.64-.15-3.92.5-5.6.65-1.68 1.92-3.61 3.38-4.46 1.46-.86 4.61-.61 5.35-.68.74-.07-.82.15-.91.25m-3.02-.29c1.65.03 5.58 1.81 6.86 2.8 1.29.99.55 1.46.84 3.13.29 1.67 1.23 5.21.89 6.89-.34 1.68-1.42 2.67-2.93 3.19-1.52.51-4.46.78-6.16-.11-1.7-.89-2.97-3.91-4.04-5.25-1.08-1.33-2.88-1.17-2.42-2.75.45-1.58 3.72-5.66 5.14-6.7 1.43-1.04 2.69.54 3.43.44.75-.1.92-.97 1.05-1.07"
        strokeWidth={0}
      />
      <path
        d="M136.955 335.235c1.53-.29 3.13.9 4.42 1.76 1.29.87 2.89 1.89 3.3 3.41.42 1.52-.13 4.26-.82 5.72-.68 1.46-2.12 2.61-3.27 3.06-1.16.44-2.13-.01-3.66-.4-1.53-.38-4.31-.94-5.52-1.91s-1.89-2.31-1.74-3.91c.15-1.6 1.42-4.25 2.66-5.68 1.24-1.44 3.65-2.55 4.78-2.92 1.13-.36 2.04.32 1.99.73m-2.56 1.42c1.44-.55 4.24-2.15 5.54-1.95 1.31.19 1.96 1.4 2.31 3.11.35 1.72.38 5.64-.19 7.18-.56 1.54-2.28 1.49-3.2 2.08-.92.58-1.17 1.2-2.31 1.43-1.14.23-3.21.72-4.54-.07-1.32-.79-3.11-3.08-3.42-4.67-.3-1.6.64-3.49 1.58-4.9.94-1.41 3.4-2.9 4.06-3.55.65-.65.1-.49-.1-.34"
        stroke="#000"
        fill="none"
      />
    </g>
    <g fillRule="evenodd" strokeLinecap="round">
      <path
        d="m122.276 357.766 8.96-3.83 4.89-.21 9.34 2.35 4.95 3.93-1.42 6.32-9.37.32-7.09-1.4-8.09-7.94"
        strokeWidth={0}
      />
      <path
        d="M122.536 358.956c1.08-.85 3.48-2.61 6.02-3.8 2.54-1.18 6.26-3.35 9.2-3.3 2.94.04 6.11 2.38 8.42 3.56 2.31 1.18 5.39 1.87 5.46 3.53.07 1.67-3.13 5.14-5.04 6.47-1.91 1.32-4.49 1.46-6.42 1.46-1.93 0-3.09-.93-5.17-1.46-2.07-.54-5.13-.88-7.27-1.75-2.13-.86-4.75-2.65-5.52-3.43m2.13-2.49c1.55-1.27 4.27-3.5 6.38-4.26 2.11-.76 3.71-.26 6.27-.28 2.57-.03 6.35-.92 9.13.12 2.78 1.04 7.32 4.18 7.55 6.11.22 1.93-3.99 4.43-6.21 5.47-2.22 1.03-4.92.19-7.11.73-2.18.55-4.15 3.11-5.99 2.54-1.85-.57-3.07-4.63-5.06-5.95-1.99-1.31-5.89-1.54-6.88-1.94"
        stroke="#000"
        fill="none"
      />
    </g>
    <g strokeLinecap="round">
      <path
        d="M141.605 422.467c4.07.42 9.32 3.49 12.14 6.84 2.82 3.35 4.74 8.71 4.78 13.26.03 4.55-1.99 10.67-4.57 14.04-2.58 3.36-6.52 5.59-10.91 6.14-4.4.56-11.49-.51-15.46-2.8-3.97-2.28-6.96-6.9-8.34-10.89-1.39-3.98-1.49-8.89.01-13 1.5-4.1 4.73-9.31 8.97-11.64 4.23-2.33 13.68-2.31 16.43-2.35 2.74-.04.37 1.7.05 2.11m-6.15-3.34c4.19-.22 11.94 1.75 15.14 4.94 3.21 3.18 3.64 9.36 4.08 14.17.44 4.8.45 11.14-1.43 14.64-1.88 3.51-5.83 5.39-9.85 6.39-4.01 1-10.11 1.15-14.23-.4-4.12-1.56-8.32-5.38-10.51-8.92-2.19-3.54-3.14-8.24-2.63-12.33.51-4.09 2.33-9.16 5.7-12.2 3.36-3.04 12.1-5.16 14.47-6.04 2.38-.88-.13.14-.24.76"
        strokeWidth={0}
        fill="#fff"
      />
      <path
        d="M140.455 422.547c4.38-.02 10.01 1.63 13.01 4.68 3 3.04 4.75 8.89 4.98 13.59.22 4.71-1.08 11.15-3.64 14.64-2.55 3.5-7.39 5.29-11.69 6.31-4.3 1.03-10.33 1.71-14.1-.16-3.78-1.88-6.95-7.02-8.54-11.08-1.6-4.06-2.19-9.21-1.01-13.27 1.18-4.05 3.64-8.75 8.09-11.07 4.45-2.32 14.68-2.86 18.63-2.84 3.96.02 5.16 2.8 5.1 2.97m-11.8-4.51c4.08.25 10.19 2.12 13.4 5.55 3.21 3.43 5.2 10.52 5.86 15.02.66 4.51.5 8.52-1.89 12.02-2.4 3.5-8.19 7.73-12.48 8.98-4.29 1.25-8.94.48-13.26-1.49-4.31-1.96-10.53-6.33-12.62-10.32-2.09-3.98-1.41-9.17.08-13.59 1.49-4.43 5.12-10.31 8.85-12.94 3.74-2.64 11.37-2.65 13.55-2.89 2.19-.23-.16 1.25-.45 1.48"
        stroke="#000"
        fill="none"
      />
    </g>
    <g strokeLinecap="round">
      <path
        d="M140.086 428.809c1.49-.06 3.36.76 4.47 1.85 1.1 1.08 1.9 2.86 2.16 4.68.26 1.81.37 4.91-.58 6.2-.96 1.28-3.39 1.16-5.16 1.5-1.78.34-3.97 1-5.5.53-1.54-.47-3.22-1.94-3.73-3.34-.5-1.4.22-3.63.69-5.06.47-1.43.84-2.47 2.15-3.53 1.3-1.05 4.4-2.45 5.69-2.82 1.29-.38 2.02.32 2.06.6m-1.01 1.83c1.27.46 2.25-.23 3.1 1.04.85 1.28 2.04 4.59 2.02 6.61-.01 2.02-.57 4.38-2.12 5.5-1.55 1.12-5.25 1.4-7.17 1.25-1.93-.15-3.42-.91-4.4-2.15-.98-1.24-1.74-3.29-1.48-5.29.26-2.01 1.89-5.3 3.06-6.75 1.17-1.45 2.73-1.64 3.93-1.93 1.21-.28 2.71.23 3.31.23.59.01.05-.13.28-.2"
        strokeWidth={0}
      />
      <path
        d="M136.906 430.139c1.2-.81 2.25-1.57 3.65-1.26 1.4.3 3.6 1.77 4.75 3.1 1.14 1.33 2.42 3.31 2.1 4.88-.32 1.57-2.79 3.21-4.04 4.54-1.26 1.33-1.94 3.09-3.49 3.45-1.56.36-4.54-.34-5.82-1.27-1.29-.93-1.76-2.62-1.89-4.31-.13-1.69.19-4.26 1.11-5.85.93-1.58 3.51-3.08 4.45-3.66.93-.58 1.28.05 1.15.18m1.03-.98c1.61.16 2.61.74 3.96 2.19 1.35 1.44 3.95 4.74 4.13 6.5.17 1.76-2.03 2.76-3.09 4.05s-1.87 3.59-3.27 3.67c-1.41.07-3.68-2.27-5.15-3.22-1.47-.94-3.1-1.29-3.68-2.43-.57-1.14-.07-2.97.24-4.41s.42-3.27 1.62-4.25c1.2-.98 4.47-1.17 5.58-1.6 1.1-.44 1.17-1.29 1.05-1.02"
        stroke="#000"
        fill="none"
      />
    </g>
    <g fillRule="evenodd" strokeLinecap="round">
      <path
        d="m124.747 453.85 5.73-4.58 6.9-2.72 11.05.67 3.19 5.85-3.54 4.9-6.62 5.07-4.26-2.42-12.05-5.96"
        strokeWidth={0}
      />
      <path
        d="M124.737 452.68c.89-.98 3.77-3.07 6.28-4.15 2.51-1.08 6.09-2.19 8.78-2.31 2.69-.12 5.12.59 7.35 1.58 2.23.99 5.56 2.69 6.03 4.35.48 1.66-1.2 4.29-3.18 5.61-1.98 1.32-6.35 1.66-8.7 2.3-2.36.64-3.36 1.71-5.42 1.56-2.06-.16-5.08-1.31-6.94-2.49-1.86-1.19-3.6-3.69-4.2-4.63m-1.34.28c.65-.85 4.09-3.59 6.77-4.79 2.67-1.2 6.49-2.15 9.29-2.43 2.8-.28 5.46.02 7.5.76s4.52 1.78 4.75 3.7c.24 1.92-1.64 6.18-3.36 7.82-1.72 1.64-4.72 1.71-6.94 2.02-2.22.31-4.55.42-6.37-.18-1.82-.6-2.98-2.23-4.55-3.43-1.57-1.19-4.14-3.11-4.86-3.76"
        stroke="#000"
        fill="none"
      />
    </g>
    <g strokeLinecap="round">
      <path
        d="M138.608 225.126c4.19.05 9.3 2.81 12.49 5.89 3.19 3.09 6.34 8.07 6.66 12.61.32 4.54-1.98 11.04-4.72 14.64-2.74 3.61-7.68 6.09-11.71 7-4.03.9-8.72.48-12.47-1.56-3.75-2.05-8.06-6.65-10.02-10.71-1.96-4.07-2.88-9.38-1.75-13.67 1.13-4.29 4.01-9.94 8.52-12.06 4.52-2.13 14.71-1.05 18.59-.68 3.89.38 4.89 2.83 4.71 2.94m-11.44-3.98c4.27.13 11.61 1.4 14.75 4.3 3.15 2.9 4.02 8.66 4.12 13.1.1 4.43-1.2 9.78-3.51 13.5-2.31 3.72-6.34 7.77-10.36 8.83-4.03 1.05-9.9-.63-13.78-2.51-3.87-1.88-7.46-4.74-9.47-8.76-2.01-4.01-3.78-11.22-2.61-15.33 1.18-4.11 6.12-7.13 9.65-9.31 3.52-2.17 9.38-3.23 11.51-3.74 2.14-.51 1.34.61 1.3.7"
        strokeWidth={0}
        fill="#fff"
      />
      <path
        d="M145.398 225.786c4.27 1.49 8.8 7.04 10.76 10.93 1.96 3.88 2.2 8.31 1.01 12.36-1.2 4.05-4.64 9.27-8.19 11.96-3.55 2.68-8.75 4.32-13.09 4.17-4.34-.15-9.75-2.36-12.96-5.08-3.21-2.72-5.61-7.01-6.28-11.25-.67-4.23-.16-10.36 2.29-14.17 2.45-3.8 7.74-7.46 12.41-8.68 4.67-1.21 12.45.93 15.61 1.38 3.17.45 3.4 1.14 3.38 1.32m-24.12-2.19c3.48-2.04 9.49-2.55 13.79-1.31 4.3 1.24 9.02 5.04 12.01 8.76 2.98 3.73 5.66 9.51 5.89 13.59.23 4.08-1.67 7.66-4.52 10.89-2.85 3.23-8.04 7.8-12.56 8.48-4.52.67-10.49-1.69-14.58-4.44-4.09-2.76-8.81-8.11-9.98-12.08-1.18-3.98 1-7.94 2.93-11.77 1.93-3.82 7.19-9.1 8.65-11.17 1.45-2.06-.13-1.29.07-1.22"
        stroke="#000"
        fill="none"
      />
    </g>
    <g strokeLinecap="round">
      <path
        d="M136.32 231.788c1.36-.34 3.47.74 4.77 1.45 1.31.71 2.37 1.5 3.07 2.8.71 1.31 1.66 3.25 1.16 5.03-.49 1.78-2.58 4.54-4.13 5.65-1.54 1.1-3.42 1.32-5.11 1-1.7-.33-3.97-1.75-5.05-2.98-1.07-1.22-1.35-2.79-1.42-4.38-.06-1.59-.37-3.85 1.03-5.15 1.41-1.3 5.9-2.15 7.39-2.63 1.49-.49 1.74-.41 1.53-.3m-.5.61c1.51.06 1.96-.53 2.81.32.85.85 2.17 2.96 2.29 4.77.12 1.82-.73 4.57-1.57 6.13-.84 1.56-1.84 2.67-3.46 3.21-1.62.54-4.85.88-6.27.05-1.41-.82-1.58-3.57-2.21-4.99-.63-1.42-2.18-1.9-1.56-3.54.61-1.64 3.58-5.26 5.25-6.3 1.68-1.03 3.95.29 4.79.09.85-.2.54-1.36.28-1.29"
        strokeWidth={0}
      />
      <path
        d="M133.69 233.808c1.39-.83 4.8-1.51 6.26-1.36 1.46.16 1.83 1.02 2.49 2.28.67 1.26 1.57 3.62 1.49 5.3-.09 1.67-.98 3.55-1.99 4.76-1.01 1.2-2.79 2.39-4.06 2.46-1.26.08-2.37-1.19-3.51-2.02-1.14-.83-2.81-1.44-3.34-2.95-.53-1.5-.58-4.58.17-6.07.75-1.49 3.44-2.31 4.32-2.87.88-.57 1.05-.35.96-.52m-.81-1.74c1.63-.41 3.92-.2 5.59.84 1.68 1.03 3.7 3.51 4.46 5.38.76 1.88.99 4.25.1 5.88-.9 1.62-4.05 3.19-5.48 3.87-1.43.68-1.62.8-3.11.21-1.48-.6-4.41-2.76-5.82-3.78-1.4-1.01-2.86-1-2.61-2.33.25-1.32 2.82-4.24 4.13-5.62 1.3-1.38 3.34-2.3 3.7-2.66.35-.37-1.35.25-1.57.47"
        stroke="#000"
        fill="none"
      />
    </g>
    <g fillRule="evenodd" strokeLinecap="round">
      <path
        d="m122.7 258.039 5.15-5.83 7.85-5.17 9.86 3.56 4.48 4.75-4.68 6.47-6.58 3.86-4.71-.1-9.89-10.2"
        strokeWidth={0}
      />
      <path
        d="M123.77 255.349c1.07-.74 3.51-2.51 5.67-3.43 2.16-.92 4.34-2.05 7.31-2.09 2.97-.04 8.25.92 10.53 1.84 2.27.91 3.31 2 3.12 3.65-.2 1.64-2.47 4.77-4.28 6.21-1.81 1.44-4.43 2-6.57 2.41-2.13.41-4.53.66-6.25.04-1.72-.61-2.45-2.58-4.08-3.73-1.62-1.16-4.69-2.46-5.65-3.2m-.76.32c.93-1.09 3.7-5.78 5.85-7.35 2.15-1.58 3.82-2.54 7.05-2.09 3.23.46 9.62 3.57 12.34 4.81 2.72 1.24 4.15 1.35 3.97 2.63-.17 1.29-2.97 3.88-5.03 5.09-2.07 1.21-4.85 1.62-7.34 2.18-2.5.56-5.73 1.26-7.65 1.17-1.91-.08-2.02-.51-3.84-1.67-1.83-1.15-6.41-4.29-7.08-5.24"
        stroke="#000"
        fill="none"
      />
    </g>
    <text
      y={18}
      fontFamily="Virgil, Segoe UI Emoji"
      fontSize={20}
      fill="#343a40"
      style={{
        whiteSpace: "pre",
      }}
      transform="translate(322.539 411.737)"
    >
      {"Aliquam sed magna quam. Proin interdum id purus "}
    </text>
    <text
      y={43}
      fontFamily="Virgil, Segoe UI Emoji"
      fontSize={20}
      fill="#343a40"
      style={{
        whiteSpace: "pre",
      }}
      transform="translate(322.539 411.737)"
    >
      {"Bibendum feugiat. Integer vitae est magna. Nunc "}
    </text>
    <text
      y={68}
      fontFamily="Virgil, Segoe UI Emoji"
      fontSize={20}
      fill="#343a40"
      style={{
        whiteSpace: "pre",
      }}
      transform="translate(322.539 411.737)"
    >
      {"fermentum vel elit eu condimentum. "}
    </text>
  </svg>
)

export default SvgComponent
